import React, { Component } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { getSite, getSitePages, getSiteSubs } from 'services/CommonServices';
import SiteSubGenerator from 'comp/SiteSubGenerator';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './style.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { site: { hasBackground: 0 }, pages: null, siteSubs: null };
  }

  componentDidMount() {
    const memberId = process.env.REACT_APP_MEMBERS_ID;
    getSite(memberId)
      .then((site) => {
        getSitePages(memberId)
          .then((result) => {
            const pages = result.data.data;
            const pageQueries = [];

            for (let i = 0; i < pages.length; i++) {
              pageQueries.push(getSiteSubs(pages[i].id));
            }

            Promise.all(pageQueries).then((pageValues) => {
              const pagesResult = pageValues.map((value) => {
                return { pageId: value.data.pageId, data: value.data.data };
              });
              pages.map((page) => {
                const pageResult = pagesResult.find(
                  (pageResult) => Number(pageResult.pageId) === Number(page.id)
                );
                page.siteSubs = pageResult.data || [];
              });

              this.setState({ site: site.data.data, pages });
            });
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });

    AOS.init();
  }

  componentWillReceiveProps() {
    AOS.refresh();
  }

  render() {
    const site = this.state.site;
    const pages = this.state.pages;
    const SiteContainer = styled.div`
      transition: all 0.3s;
      ${site.hasBackground &&
      `
        body {
          background-color: #DDD;
        }
        
        position: relative;
        overflow: hidden;
        background-color: #FFF;
        margin-top: 30px;
        margin-bottom: 30px;
        border-radius: 10px;
        box-shadow: 0px 0px 15px rgba(136, 136, 136, 0.3);

        @media (max-width: 768px) {
          margin-top: 0;
          margin-bottom: 0;
          border-radius: 0;
        }
      `}
    `;

    return pages ? (
      <SiteContainer className={`App ${site.hasBackground && 'container'}`}>
        {pages.length > 1 ? (
          <div className="container">다중 페이지</div>
        ) : (
          <div>
            {pages.map((page) => {
              return [
                <Helmet key="siteSEO">
                  {page.seo ? (
                    page.seo.map((seoElement) => {
                      if (seoElement.name === 'title')
                        return <title>{seoElement.content}</title>;
                      else if (seoElement.name === 'favicon')
                        return (
                          <link
                            rel="icon"
                            type="image/png"
                            sizes="32x32"
                            href={seoElement.content}
                          />
                        );
                      return (
                        <meta
                          name={seoElement.name}
                          content={seoElement.content}
                        />
                      );
                    })
                  ) : (
                    <title>Loading...</title>
                  )}
                </Helmet>,
                page.siteSubs ? (
                  page.siteSubs.map((siteSub) => (
                    <SiteSubGenerator site={site} siteSub={siteSub} />
                  ))
                ) : (
                  <div></div>
                ),
              ];
            })}
          </div>
        )}
      </SiteContainer>
    ) : (
      <SiteContainer className="App">
        <header className="App-header">Loading...</header>
      </SiteContainer>
    );
  }
}

export default App;
