import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class ContactUsContainer extends React.Component {
  render() {
    const intl = this.props.intl;
    const ContactUsContainer = styled.section`
      & .cc-row {
        ${this.props.backgroundImage
          ? `
            background-image: url(${this.props.backgroundImage});
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          `
          : `background-color: ${this.props.backgroundColor};`}
      }

      & input::placeholder {
        color: #ddd;
      }

      & .contact-portrait {
        width: 85% !important;
        animation: fadein 4s;
        animation-fill-mode: forwards;
      }
    `;
    return (
      <ContactUsContainer className={this.props.containerClass}>
        <form action={this.props.formEndpoint} method="post">
          <div className="row cc-row justify-content-center align-items-center px-4 pt-4 pt-sm-0">
            {this.props.phone && (
              <div className="col-md-4 col-xs-6 col-sm-6 text-center">
                <img
                  className="mb-3"
                  src="https://img.icons8.com/ios/30/000000/survey.png"
                  alt="survey"
                />
                <p className="mb-1 fs-5 fw-light">
                  {intl.formatMessage({ id: 'header.contact.label' })}
                </p>
                <p
                  className="mb-1 fs-2 fw-bold"
                  style={{ fontFamily: `'Lato'` }}
                >
                  {this.props.phone}
                </p>
                {this.props.googleFormLink && (
                  <a
                    href={this.props.googleFormLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <span className="fs-5 fw-bold text-dark">
                        {intl.formatMessage({
                          id: 'header.contact.consultation',
                        })}
                        {'  '}
                      </span>
                      <img
                        src="https://img.icons8.com/fluency-systems-filled/20/E1856D/more-than.png"
                        alt="more"
                      />
                    </div>
                  </a>
                )}
              </div>
            )}
            <div className="col-xs-6 col-sm-6 d-none d-sm-block d-md-none">
              <img
                className="img-fluid float-end contact-portrait"
                style={{
                  padding: this.props.padding,
                  margin: this.props.margin,
                }}
                src={this.props.portrait}
                alt="Portrait"
              />
            </div>
            <div
              className="col-lg-4 my-4 col-md-8 col-sm-8 bg-light"
              style={{ '--bs-bg-opacity': '0.5' }}
            >
              <div className="row mt-3 text-left fw-light">
                <div className="col-md-12 mb-3">
                  <label className="labels mb-1">
                    {intl.formatMessage({ id: 'common.name' })}*
                  </label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    className="form-control form-control-sm"
                    required
                    placeholder={intl.formatMessage({
                      id: 'common.name.placeholder',
                    })}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label className="labels mb-1">
                    {intl.formatMessage({ id: 'common.email' })}*
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="form-control form-control-sm"
                    required
                    placeholder={intl.formatMessage({
                      id: 'common.email.placeholder',
                    })}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label className="labels mb-1">
                    {intl.formatMessage({ id: 'common.phone' })}*
                  </label>
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    className="form-control form-control-sm"
                    required
                    placeholder={intl.formatMessage({
                      id: 'common.phone.placeholder',
                    })}
                  />
                </div>
              </div>
              <div className="mb-4 text-center">
                <button
                  className="btn btn-sm btn-secondary border-0 profile-button bg-red w-50"
                  type="submit"
                >
                  {intl.formatMessage({ id: 'header.cma' })}
                </button>
              </div>
            </div>
            <div className="col-lg-4 d-lg-block text-center d-md-none d-sm-none d-xs-none">
              <img
                className="img-fluid contact-portrait"
                src={this.props.portrait}
                style={{
                  padding: this.props.padding,
                  margin: this.props.margin,
                }}
                data-aos="fade-left"
                data-aos-duration="2000"
                alt="Portrait"
              />
            </div>
          </div>
        </form>
      </ContactUsContainer>
    );
  }
}

ContactUsContainer.propTypes = {
  containerClass: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  phone: PropTypes.string,
  googleFormLink: PropTypes.string,
  formEndpoint: PropTypes.string,
  portrait: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
};

ContactUsContainer.defaultProps = {
  containerClass: 'mb-5',
  backgroundColor: '#E6E6E8',
  backgroundImage: '',
  padding: '0',
  margin: '0',
  formEndPoint: '',
};

export default injectIntl(ContactUsContainer);
