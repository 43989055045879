import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import $ from 'jquery';
import styled from 'styled-components';

class LeftFooterMenuType01 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const currentTop = parseInt($('#left-fixed-menu').css('top'));
    $(window).on('scroll', function () {
      $('#left-fixed-menu')
        .stop()
        .animate({ top: currentTop + 'px' }, 500);
    });
  }

  render() {
    const intl = this.props.intl;
    const LeftFooterMenuContainer = styled.section`
      font-size: 14px;
      color: #000;
      line-height: 1.5;
      box-sizing: border-box;
      position: fixed;
      width: 60px;
      height: 320px;
      left: 0;
      top: 220px;

      & > ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: block;
        width: 100%;

        border-radius: 0 6px 6px 0;
        overflow: hidden;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
      }
      & > ul > li {
        -webkit-text-size-adjust: 100%;
        letter-spacing: -0.5px;
        font-size: 14px;
        color: #000;
        line-height: 1.5;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        list-style: none;
        display: block;
        width: 100%;
        height: 80px;
      }
      & > ul > li > a {
        background: ${this.props.backgroundColor};
        display: block;
        width: 100%;
        box-sizing: border-box;
        color: #fff;
        transition: all 0.3s;
        height: 100%;
        font-size: 10px;
        text-align: center;
        margin: 0 auto;
        padding: 5px;
      }
      & > ul > li > a:hover {
        background: #1d3745;
      }
      & > ul > li.lf_tel > a > p {
        width: 50px;
        height: 50px;
        background: url('https://i.ibb.co/7RNX1LK/icon-lf-tel.png') 0 0;
      }
      & > ul > li.lf_email > a > p {
        width: 50px;
        height: 50px;
        background: url('https://i.ibb.co/VW20Vy4/icon-lf-email.png') 0 0;
      }
      & > ul > li.lf_kakao > a > p {
        width: 50px;
        height: 50px;
        background: url('https://i.ibb.co/tJwsRHq/icon-lf-kakao.png') 0 0;
      }
      & > ul > li.lf_contact > a > p {
        width: 50px;
        height: 50px;
        background: url('https://i.ibb.co/WyTZcm4/icon-lf-contact.png') 0 0;
      }
      & > ul > li.lf_contact > a {
        border: none;
      }
      & > ul > li.lf_tel:hover > ul {
        display: block;
        z-index: 99;
      }
      & > ul > li.lf_tel > ul {
        position: absolute;
        width: 200px;
        height: 100px;
        background: #fff;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
        border-radius: 0 8px 8px 8px;
        overflow: hidden;
        top: 0px;
        left: 60px;
        display: none;
      }
      & > ul > li.lf_tel > ul > li.lf_tel_li1 {
        position: absolute;
        top: 10px;
        right: 15px;
        font-size: 11px;
        color: #e80032;
      }
      & > ul > li.lf_tel > ul > li.lf_tel_li2 > p {
        display: block;
        width: 26px;
        height: 26px;
        background: url('http://www.mobiwebi.com/assets/img/web/icon_side_tel.png')
          0 0;
        position: absolute;
        top: 35px;
        left: 15px;
      }
      & > ul > li.lf_tel > ul > li.lf_tel_li2 > span {
        color: #2e2f36;
        font-family: 'Lato';
        font-weight: 800;
        font-size: 22px;
        position: absolute;
        top: 31px;
        left: 45px;
      }
      & > ul > li.lf_tel > ul > li.lf_tel_li3 {
        font-size: 12px;
        color: #6f7075;
        position: absolute;
        bottom: 15px;
        right: 15px;
      }
      & > ul > li.lf_email:hover > ul {
        display: block;
        z-index: 99;
      }
      & > ul > li.lf_email > ul {
        position: absolute;
        min-width: 350px;
        height: 85px;
        background: #fff;
        border-radius: 0 8px 8px 8px;
        overflow: hidden;
        top: 80px;
        left: 60px;
        display: none;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
      }
      & > ul > li.lf_email > ul > li.lf_email_li1 {
        position: absolute;
        top: 10px;
        right: 15px;
        font-size: 11px;
        color: #e80032;
      }
      & > ul > li.lf_email > ul > li.lf_email_li2 > p {
        display: block;
        width: 26px;
        height: 26px;
        background: url('http://www.mobiwebi.com/assets/img/web/icon_side_email.png')
          0 0;
        position: absolute;
        top: 35px;
        left: 15px;
      }
      & > ul > li.lf_email > ul > li.lf_email_li2 > span {
        color: #2e2f36;
        font-family: 'Lato';
        font-weight: 700;
        font-size: 15px;
        position: absolute;
        top: 36px;
        left: 50px;
      }
    `;
    return (
      <LeftFooterMenuContainer
        id="left-fixed-menu"
        className={this.props.containerClass}
      >
        <ul>
          {this.props.phone && (
            <li class="fw-normal lf_tel">
              <a href="#">
                <p className="my-0"></p>
                {intl.formatMessage({ id: 'menu.phone' })}
              </a>
              <ul>
                <li className="lf_tel_li1">
                  {intl.formatMessage({ id: 'menu.phone.label' })}
                </li>
                <li className="lf_tel_li2">
                  <p className="my-0"></p>
                  <span>{this.props.phone}</span>
                </li>
                <li className="lf_tel_li3">{this.props.phoneAvailable}</li>
              </ul>
            </li>
          )}
          {this.props.email && (
            <li className="fw-normal lf_email">
              <a href={`mailto:${this.props.email};`}>
                <p className="my-0"></p>
                {intl.formatMessage({ id: 'menu.email' })}
              </a>
              <ul>
                <li class="lf_email_li1">
                  {intl.formatMessage({ id: 'menu.email' })}
                </li>
                <li class="lf_email_li2">
                  <p className="my-0"></p>
                  <span>{this.props.email}</span>
                </li>
              </ul>
            </li>
          )}

          {this.props.kakao && (
            <li className="fw-normal lf_kakao">
              <a href={this.props.kakao} target="_blank" rel="noreferrer">
                <p className="my-0"></p>
                {intl.formatMessage({ id: 'menu.kakao' })}
              </a>
            </li>
          )}
          {this.props.googleFormLink && (
            <li class="fw-normal lf_contact">
              <a
                href={this.props.googleFormLink}
                target="_blank"
                rel="noreferrer"
              >
                <p className="my-0"></p>
                {intl.formatMessage({ id: 'menu.form' })}
              </a>
            </li>
          )}
        </ul>
      </LeftFooterMenuContainer>
    );
  }
}

LeftFooterMenuType01.propTypes = {
  containerClass: PropTypes.string,
  backgroundColor: PropTypes.string,
  phone: PropTypes.string,
  phoneAvailable: PropTypes.string,
  email: PropTypes.string,
  kakao: PropTypes.string,
  googleFormLink: PropTypes.string,
};

LeftFooterMenuType01.defaultProps = {
  containerClass: '',
  backgroundColor: '#c81f21',
};

export default injectIntl(LeftFooterMenuType01);
