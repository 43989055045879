import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import styled from 'styled-components';
import {
  getConfirmedLatestYear,
  getPropertySubs,
} from 'services/CommonServices';
import $ from 'jquery';
import numeral from 'numeral';

class PropertyContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { propertySubs: [] };
  }
  componentDidMount() {
    const memberId = process.env.REACT_APP_MEMBERS_ID;

    getConfirmedLatestYear()
      .then((latestYear) => {
        const year = latestYear.data.data.year;
        const month = latestYear.data.data.month;

        getPropertySubs(memberId, year, month)
          .then((propertySubs) => {
            this.setState({ propertySubs: propertySubs.data.data });
            window.$('.bm-thumbnail-images').slick({
              slidesToShow: 1,
              dots: true,
            });
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    const intl = this.props.intl;
    const propertySubs = this.state.propertySubs || [];
    const PropertyContainer = styled.div`
      /* bm-checkbox */
      .bm-checkbox {
        font-size: 16px;
      }

      /* bm-card */
      .bm-card-header {
        height: 47px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      /* bm-thumbnail */
      & .bm-thumbnail {
        padding: 0;
        position: relative;
        overflow: hidden;
      }

      & .bm-thumbnail-video {
        position: absolute;
        bottom: 9px;
        left: 3px;
        border: 2px solid #c81f21;
        z-index: 1;
        background-color: rgb(255, 255, 255);
        padding: 3px 10px;
      }

      & .bm-thumbnail-video > a {
        color: #000;
      }

      & .bm-thumbnail-corner-ribbon {
        width: 200px;
        background-color: #449ac0;
        position: absolute;
        top: 10px;
        right: -73px;
        text-align: center;
        line-height: 35px;
        letter-spacing: 1px;
        color: #f0f0f0;
        transform: rotate(-45deg);
        -webkit-transform: rotate(45deg);
        font-size: 15px;
        font-weight: bold;
      }

      & .bm-thumbnail-corner {
        padding: 3px 10px;
        background-color: #449ac0;
        position: absolute;
        top: 47px;
        right: 0;
        text-align: center;
        line-height: 24px;
        letter-spacing: 1px;
        color: #f0f0f0;
      }

      & .bm-thumbnail-title {
        font-size: 23px;
      }

      & .bm-thumbnail-price {
        font-family: 'Lato';
        font-size: 20px;
        font-weight: bold;
      }

      & .bm-thumbnail-ribbon {
        position: absolute;
        color: #fff;
        left: 0;
        padding: 3px 10px;
        background-color: rgba(0, 0, 0, 0.5);
      }

      & .bm-thumbnail-ribbon-bottom {
        position: absolute;
        color: #fff;
        right: 0;
        bottom: 5px;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.5);
      }

      & .bm-thumbnail-image {
        display: block;
        position: relative;
        height: 250px;
        background-size: cover;
        background-position: center center;
      }

      & .bm-thumbnail-image-container {
        position: relative;
      }

      & .bm-thumbnail-image-container > img {
        width: 100%;
      }

      & .bm-thumbnail-caption {
        height: 90px;
        padding: 10px;
        text-align: left;
      }

      & .bm-thumbnail-desc {
        font-size: 1em;
        word-break: keep-all;
      }

      @media (max-width: 768px) {
        & .bm-thumbnail-desc {
          font-size: 0.8em;
        }
      }

      @media (max-width: 576px) {
        & .bm-thumbnail-caption {
          height: 80px;
        }
      }

      /*** Slick **/

      .slick-dots li {
        margin: 0;
      }

      .slick-dots {
        bottom: 10px;
      }

      .slick-dotted.slick-slider {
        margin: 0;
      }
    `;

    return (
      <section className={this.props.containerClass}>
        <div className="row py-3">
          <div className="col-md-12 text-center">
            <h3 className="vs-title fw-bold mb-3">
              {intl.formatMessage({ id: 'properties.title' })}
            </h3>
            <span className="vs-subtitle">
              {intl.formatMessage({ id: 'properties.subtitle' })}
            </span>
          </div>
        </div>
        <PropertyContainer className="row">
          <h5 className="bm-subtitle mt-4 mb-3">
            {intl.formatMessage({ id: 'properties.label.01' })}
          </h5>
          {propertySubs.map((property, idx) => {
            const baths =
              property.fullBaths + (property.halfBaths > 0 ? 0.5 : 0);
            const options = property.objects
              ? JSON.parse(property.objects)
              : {};
            return (
              <div
                className="col-sm-12 col-md-6 col-lg-4 mb-4 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay={idx * 50}
                key={property.id}
              >
                <div className="card bm-thumbnail">
                  <div className="card-header text-start bm-card-header">
                    <div className="pretty p-default bm-checkbox">
                      <input
                        type="checkbox"
                        name={
                          property.title ||
                          property.neighborhood +
                            ', ' +
                            numeral(property.price).format('$0,0')
                        }
                      />
                      <div className="state p-success">
                        <label>
                          {property.title
                            ? Parser(property.title)
                            : property.neighborhood}
                        </label>
                      </div>
                    </div>
                    <span className="bm-thumbnail-price">
                      {property.price > 0 &&
                        numeral(property.price).format('$0,0')}
                      {property.priceUnit && property.priceUnit}
                    </span>
                  </div>
                  <div className="bm-thumbnail-image-container">
                    {(property.video || property.matterport) && (
                      <div className="bm-thumbnail-video">
                        {property.video && (
                          <a href={property.video} data-lity>
                            <img src="https://img.icons8.com/material/24/c81f21/video.png" />{' '}
                            {intl.formatMessage({ id: 'properties.video' })}
                          </a>
                        )}
                        {property.matterport && (
                          <a href={property.matterport} data-lity>
                            <img src="https://img.icons8.com/ios-filled/24/c81f21/3d-recycle-center.png" />{' '}
                            {intl.formatMessage({
                              id: 'properties.virtualtour',
                            })}
                          </a>
                        )}
                      </div>
                    )}
                    {property.mlsId && property.picNum > 0 ? (
                      <div className="bm-thumbnail-images">
                        {((property) => {
                          if (property.mlsId && property.picNum > 0) {
                            const propertyPics = [];
                            for (
                              let index = 1;
                              index <= property.picNum;
                              index++
                            ) {
                              propertyPics.push(
                                `http://pxlimages.xmlsweb.com/njmls/l/images/${
                                  property.mlsId
                                }.${index.toString()}.jpg`
                              );
                            }
                            if (propertyPics.length !== 0)
                              return propertyPics.map((propertyPic, idx) => (
                                <div
                                  className="bm-thumbnail-image"
                                  style={{
                                    backgroundImage: `url('${propertyPic}')`,
                                  }}
                                >
                                  <div className="bm-thumbnail-ribbon">
                                    {property.memo}
                                  </div>
                                </div>
                              ));
                          }
                        })(property)}
                      </div>
                    ) : (
                      <div
                        className="bm-thumbnail-image"
                        style={{
                          backgroundImage: `url('${property.pic}')`,
                        }}
                      >
                        <div className="bm-thumbnail-ribbon">
                          {property.memo}
                        </div>
                      </div>
                    )}
                    {property.bedrooms > 0 && (
                      <div className="bm-thumbnail-ribbon-bottom">
                        {intl.formatMessage({ id: 'properties.label.beds' })}{' '}
                        {property.bedrooms} |
                        {intl.formatMessage({ id: 'properties.label.baths' })}{' '}
                        {baths}
                      </div>
                    )}
                  </div>

                  <div className="card-body bm-thumbnail-caption text-left">
                    <span className="bm-thumbnail-desc">
                      {intl.locale === 'ko'
                        ? property.descTranslated
                        : property.descOriginal || property.descTranslated}
                    </span>
                  </div>
                  {property.label && (
                    <span className="bm-thumbnail-corner">
                      {property.label}
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </PropertyContainer>
      </section>
    );
  }
}

PropertyContainer.propTypes = {
  containerClass: PropTypes.string,
  hasRibbon: PropTypes.bool,
};

PropertyContainer.defaultProps = {
  containerClass: 'container',
  hasRibbon: false,
};

export default injectIntl(PropertyContainer);
