import React from 'react';
import LoginAction from 'actions/LoginAction';

class LogoutModal extends React.Component {
  handleLogout(e) {
    LoginAction.logout();
  }

  render() {
    return (
      <div
        className="modal fade"
        id="logoutModal"
        role="dialog"
        aria-labelledby="logoutModalLabel"
      >
        <div className="modal-dialog modal-login">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ fontSize: '1.2em' }}>
              로그아웃하시겠습니까?
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn" data-dismiss="modal">
                취소
              </button>
              <button
                type="submit"
                className="btn"
                data-dismiss="modal"
                onClick={this.handleLogout}
              >
                로그아웃
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LogoutModal;
