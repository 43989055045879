import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './index.css';
import App from './components/App';
import About from './components/About';
import Admin from './components/Admin';
import NotFound from './components/NotFound';
import Header from './components/Header';
import Footer from './components/Footer';
import CommonModules from './components/CommonModules';
import reportWebVitals from './reportWebVitals';
import { IntlProvider } from 'react-intl';
import en from './locale/en';
import ko from './locale/ko';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

const defaultLang = localStorage.getItem('lang') || 'ko';
let message;
if (defaultLang === 'ko') {
  message = ko;
} else {
  message = en;
}

ReactDOM.render(
  <IntlProvider locale={defaultLang} messages={message}>
    <BrowserRouter>
      <Header />
      <CommonModules />
      <Switch>
        <Route exact path="/" component={App} />
        <Route path="/about" component={About} />
        <Route path="/admin" component={Admin} />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </BrowserRouter>
  </IntlProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
