import React from 'react';
import PropTypes from 'prop-types';

class HtmlContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      html: this.props.html,
      style: this.props.style,
      script: this.props.script,
    };
  }
  render() {
    if (this.state.scripts) eval(this.state.scripts);

    return (
      <section className={this.props.containerClass}>
        <div
          dangerouslySetInnerHTML={{
            __html: `<style>${this.state.style}</style>`,
          }}
        />
        <div
          className="event-content"
          style={{ width: '100%' }}
          ref="description"
          dangerouslySetInnerHTML={{ __html: this.state.html }}
        />
      </section>
    );
  }
}

HtmlContainer.propTypes = {
  html: PropTypes.string,
  style: PropTypes.string,
  script: PropTypes.string,
  containerClass: PropTypes.string,
};

HtmlContainer.defaultProps = {
  containerClass: '',
};

export default HtmlContainer;
