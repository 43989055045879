import axios from 'axios';
import fs from 'fs';

const API_URL = process.env.REACT_APP_API_SERVER_ENDPOINT;
const headers = {
  'Content-Type': 'application/json',
  withCredentials: true,
  credentials: 'include',
};
axios.defaults.withCredentials = true;

//const add = (data) => axios.post(`${API_URL}/form`, data, { headers });
//const getData = () => axios.get(`${API_URL}/hotels`, { headers });
const getTestData = () => axios.get(`${API_URL}/test`, { headers });
const getSite = (memberId) =>
  axios.get(`${API_URL}/get-site`, { headers, params: { memberId } });
const getSitePages = (memberId) =>
  axios.get(`${API_URL}/get-pages`, { headers, params: { memberId } });
const getSiteSubs = (pageId) =>
  axios.get(`${API_URL}/get-site-subs`, { headers, params: { pageId } });
const getConfirmedLatestYear = () =>
  axios.get(`${API_URL}/get-confirmed-latest-year`, { headers });
const getPropertySubs = (memberId, year, month) =>
  axios.get(`${API_URL}/get-property-subs`, {
    headers,
    params: { memberId, year, month },
  });
const getAuthorization = (email, password) =>
  axios.post(`${API_URL}/authenticate`, {
    headers,
    params: { email, password },
  });

const writeFilePromise = (file, data) =>
  new Promise((resolve, reject) => {
    fs.writeFile(file, data, (error) => {
      if (error) reject(error);
      resolve('File created successfully');
    });
  });

const getLocale = () => axios.get(`${API_URL}/getLocale`, { headers });

export {
  getTestData,
  getSite,
  getSitePages,
  getSiteSubs,
  getConfirmedLatestYear,
  getPropertySubs,
  getAuthorization,
  getLocale,
  writeFilePromise,
};
