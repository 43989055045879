import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderType01 from './HeaderType01';
import HtmlContainer from './HtmlContainer';
import PropertiesContainer from './PropertiesContainer';
import ContactUsContainer from './ContactUsContainer';
import VirtualShowing01 from './VirtualShowing01';
import FooterType01 from './FooterType01';
import LeftFooterMenuType01 from './LeftFooterMenuType01';

class SiteSubGenerator extends Component {
  constructor(props) {
    super(props);

    this.state = { site: this.props.site, siteSub: this.props.siteSub };

    this.getSiteSub = this.getSiteSub.bind(this);
  }

  getSiteSub(site, siteSub) {
    switch (siteSub.type) {
      case 'header-01':
        return (
          <HeaderType01
            logo={site.logo}
            title={site.title || site.eName}
            subtitle={site.subtitle}
            sns={{
              facebook: site.facebook,
              instagram: site.instagram,
              youtube: site.youtube,
            }}
          />
        );
      case 'html':
        return (
          <HtmlContainer
            html={siteSub.html}
            style={siteSub.style}
            script={siteSub.script}
          />
        );
      case 'properties-01':
        return <PropertiesContainer options={siteSub.objects} hasRibbon />;
      case 'properties-02':
        return <PropertiesContainer options={siteSub.objects} />;
      case 'contactus':
        return (
          <ContactUsContainer
            phone={site.phone}
            googleFormLink={site.googleFormLink}
            formEndpoint={site.formEndpoint}
            portrait={siteSub.objects.portrait}
            backgroundImage={siteSub.objects.backgroundImage}
            padding={siteSub.objects.padding}
            margin={siteSub.objects.margin}
          />
        );
      case 'virtualshowing-01':
        return (
          <VirtualShowing01
            phone={site.phone}
            options={siteSub.objects}
            propertyType="1"
            formEndpoint={site.formEndpoint}
          />
        );
      case 'virtualshowing-02':
        return (
          <VirtualShowing01
            phone={site.phone}
            options={siteSub.objects}
            propertyType="2"
            formEndpoint={site.formEndpoint}
          />
        );
      case 'footer-01':
        return <FooterType01 />;
      case 'left-fixed-menu-01':
        return (
          <LeftFooterMenuType01
            containerClass="d-none d-sm-none d-md-block"
            phone={site.phone}
            phoneAvailable={site.phoneAvailable}
            email={site.email}
            kakao={site.kakao}
            googleFormLink={site.googleFormLink}
          />
        );
      default:
        return <div></div>;
    }
  }

  render() {
    return this.getSiteSub(this.state.site, this.state.siteSub);
  }
}
SiteSubGenerator.propTypes = {
  site: PropTypes.shape({
    id: PropTypes.string,
    memberId: PropTypes.string,
    createdAt: PropTypes.string,
    hasBackground: PropTypes.string,
    formEndpoint: PropTypes.string,
    googleFormLink: PropTypes.string,
    name: PropTypes.string,
    eName: PropTypes.string,
    phone: PropTypes.string,
    phoneAvailable: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    youtube: PropTypes.string,
    kakao: PropTypes.string,
  }),
  siteSub: PropTypes.shape({
    type: PropTypes.string,
    objects: PropTypes.string,
    html: PropTypes.string,
    style: PropTypes.string,
    script: PropTypes.string,
    order: PropTypes.number,
  }),
};

export default SiteSubGenerator;
