import React from 'react';
import LoginAction from 'actions/LoginAction';
import $ from 'jquery';

class LoginModal extends React.Component {
  constructor(props) {
    super(props);

    this.ref_email = React.createRef();
    this.ref_password = React.createRef();
    this.clearInputs = this.clearInputs.bind(this);
  }

  clearInputs() {
    this.ref_email.current.value = '';
    this.ref_password.current.value = '';
  }

  handleSubmit(e) {
    e.preventDefault();
    const email = this.ref_email.current.value;
    const password = this.ref_password.current.value;

    LoginAction.login(email, password);
  }

  render() {
    return (
      <div id="loginModal" className="modal fade" aria-hidden="true">
        <div className="modal-dialog modal-login">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Sign In</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control rounded-4"
                  id="login-email"
                  ref={this.ref_email}
                  placeholder="name@example.com"
                />
                <label htmlFor="login-id">Email address</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="password"
                  className="form-control rounded-4"
                  id="login-password"
                  ref={this.ref_password}
                  placeholder="Password"
                />
                <label htmlFor="login-password">Password</label>
              </div>
              <input
                className="w-100 mb-2 btn btn-lg rounded-4 btn-primary"
                onClick={(e) => this.handleSubmit(e)}
                type="button"
                value="Sign In"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginModal;
